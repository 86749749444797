import React from 'react';
import Navbar from './components/Navbar';
import PriceComparison from './components/PriceComparison';
// import Calculator from './components/Calculator';
import Footer from './components/Footer';

import { ThemeProvider } from './components/ThemeContext';
import './App.css';

function App() {
  return (
    <ThemeProvider>
      <div className="App">
        <Navbar />
        <div className="content">
          <PriceComparison />
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
