import React, { useState } from 'react';
import { useTheme } from './ThemeContext';
import './PriceComparison.css';

const models = [
  {
    model: "gpt-4o",
    family: "",
    context: "128K",
    inputCost: "$5",
    outputCost: "$15",
    provider: "OpenAI",
    keywords: "gpt language-model large-context"
  },
  {
    model: "gpt-4o-mini",
    family: "",
    context: "128K",
    inputCost: "$0.15",
    outputCost: "$0.6",
    provider: "OpenAI",
    keywords: "gpt language-model large-context"
  },
  {
    model: "gpt-4o-mini-2024-07-18",
    family: "",
    context: "128K",
    inputCost: "$0.15",
    outputCost: "$0.6",
    provider: "OpenAI",
    keywords: "gpt language-model large-context"
  },
  {
    model: "gpt-4o-2024-08-06",
    family: "",
    context: "128K",
    inputCost: "$2.50",
    outputCost: "$10.00",
    provider: "OpenAI",
    keywords: "gpt language-model large-context"
  },
  {
    model: "gpt-4o-2024-05-13",
    family: "",
    context: "128K",
    inputCost: "$5.00",
    outputCost: "$15.00",
    provider: "OpenAI",
    keywords: "gpt language-model large-context"
  },
  {
    model: "gpt-4-turbo-2024-04-09",
    family: "",
    context: "128K",
    inputCost: "$10",
    outputCost: "$30",
    provider: "OpenAI",
    keywords: "gpt language-model large-context"
  },
  {
    model: "gpt-4",
    family: "",
    context: "8K",
    inputCost: "$30",
    outputCost: "$60",
    provider: "OpenAI",
    keywords: "gpt language-model"
  },
  {
    model: "gpt-4-32k",
    family: "",
    context: "32K",
    inputCost: "$60",
    outputCost: "$120",
    provider: "OpenAI",
    keywords: "gpt language-model large-context"
  },
  {
    model: "gpt-3.5-turbo-0125",
    family: "",
    context: "16K",
    inputCost: "$0.5",
    outputCost: "$1.5",
    provider: "OpenAI",
    keywords: "gpt language-model"
  },
  {
    model: "gpt-3.5-turbo-instruct",
    family: "",
    context: "4K",
    inputCost: "$1.5",
    outputCost: "$2",
    provider: "OpenAI",
    keywords: "gpt language-model"
  },
  {
    model: "gpt-4-0125-preview",
    family: "",
    context: "128K",
    inputCost: "$10",
    outputCost: "$30",
    provider: "OpenAI",
    keywords: "gpt language-model large-context preview"
  },
  {
    model: "gpt-4-1106-preview",
    family: "",
    context: "128K",
    inputCost: "$10",
    outputCost: "$30",
    provider: "OpenAI",
    keywords: "gpt language-model large-context preview"
  },
  {
    model: "gpt-4-vision-preview",
    family: "",
    context: "128K",
    inputCost: "$10",
    outputCost: "$30",
    provider: "OpenAI",
    keywords: "gpt language-model large-context vision preview"
  },
  {
    model: "gpt-3.5-turbo-1106",
    family: "",
    context: "4K",
    inputCost: "$1",
    outputCost: "$2",
    provider: "OpenAI",
    keywords: "gpt language-model"
  },
  {
    model: "gpt-3.5-turbo-0613",
    family: "",
    context: "4K",
    inputCost: "$1.5",
    outputCost: "$2",
    provider: "OpenAI",
    keywords: "gpt language-model"
  },
  {
    model: "gpt-3.5-turbo-16k-0613",
    family: "",
    context: "4K",
    inputCost: "$3",
    outputCost: "$4",
    provider: "OpenAI",
    keywords: "gpt language-model"
  },
  {
    model: "gpt-3.5-turbo-0301",
    family: "",
    context: "4K",
    inputCost: "$1.5",
    outputCost: "$2",
    provider: "OpenAI",
    keywords: "gpt language-model"
  },
  {
    model: "text-embedding-3-small",
    family: "",
    context: "N/A",
    inputCost: "$0.020",
    outputCost: "$0",
    provider: "OpenAI",
    keywords: "embedding"
  },
  {
    model: "text-embedding-3-large",
    family: "",
    context: "N/A",
    inputCost: "$0.130",
    outputCost: "$0",
    provider: "OpenAI",
    keywords: "embedding"
  },
  {
    model: "text-embedding-ada-002",
    family: "",
    context: "N/A",
    inputCost: "$0.100",
    outputCost: "$0",
    provider: "OpenAI",
    keywords: "embedding"
  },
  {
    model: "dall-e-3",
    family: "",
    context: "N/A",
    inputCost: "Varies",
    outputCost: "Varies",
    provider: "OpenAI",
    keywords: "image generation",
    detailedPricing: [
      { type: "Standard", resolution: "1024×1024", price: "$0.040 / image" },
      { type: "Standard", resolution: "1024×1792, 1792×1024", price: "$0.080 / image" },
      { type: "HD", resolution: "1024×1024", price: "$0.080 / image" },
      { type: "HD", resolution: "1024×1792, 1792×1024", price: "$0.120 / image" },
    ]
  },
  {
    model: "dall-e-2",
    family: "",
    context: "N/A",
    inputCost: "Varies",
    outputCost: "Varies",
    provider: "OpenAI",
    keywords: "image generation",
    detailedPricing: [
      { resolution: "1024×1024", price: "$0.020 / image" },
      { resolution: "512×512", price: "$0.018 / image" },
      { resolution: "256×256", price: "$0.016 / image" },
    ]
  },
  {
    model: "tts-1",
    family: "Audio",
    context: "N/A",
    inputCost: "$15.000",
    outputCost: "N/A",
    provider: "OpenAI",
    keywords: "text-to-speech standard",
    detailedPricing: [
      { type: "Standard", resolution: "per 1M characters", price: "$15.000" }
    ]
  },
  {
    model: "tts-1-1106",
    family: "Audio",
    context: "N/A",
    inputCost: "$15.000",
    outputCost: "N/A",
    provider: "OpenAI",
    keywords: "text-to-speech standard updated",
    detailedPricing: [
      { type: "Standard", resolution: "per 1M characters", price: "$15.000" }
    ]
  },
  {
    model: "tts-1-hd",
    family: "Audio",
    context: "N/A",
    inputCost: "$30.000",
    outputCost: "N/A",
    provider: "OpenAI",
    keywords: "text-to-speech high-definition",
    detailedPricing: [
      { type: "HD", resolution: "per 1M characters", price: "$30.000" }
    ]
  },
  {
    model: "tts-1-1106-hd",
    family: "Audio",
    context: "N/A",
    inputCost: "$30.000",
    outputCost: "N/A",
    provider: "OpenAI",
    keywords: "text-to-speech high-definition updated",
    detailedPricing: [
      { type: "HD", resolution: "per 1M characters", price: "$30.000" }
    ]
  },
  {
    model: "whisper-1",
    family: "Audio",
    context: "N/A",
    inputCost: "$0.006",
    outputCost: "N/A",
    provider: "OpenAI",
    keywords: "text-to-speech high-definition updated",
    detailedPricing: [
      { type: "whisper", resolution: "per minute (rounded to the nearest second)", price: "$0.006" }
    ]
  },
  {
    model: "claude-3-sonnet-20240229",
    family: "Claude",
    context: "200K",
    inputCost: "$3.000",
    outputCost: "$15.000",
    provider: "Anthropic",
    keywords: "balance speed cost performance"
  },
  {
    model: "claude-2.1",
    family: "Claude",
    context: "200K",
    inputCost: "$8.000",
    outputCost: "$24.000",
    provider: "Anthropic",
    keywords: ""
  },
  {
    model: "claude-2.0",
    family: "Claude",
    context: "100K",
    inputCost: "$8.000",
    outputCost: "$24.000",
    provider: "Anthropic",
    keywords: ""
  },
  {
    model: "claude-instant-1.2",
    family: "Claude",
    context: "100K",
    inputCost: "$0.800",
    outputCost: "$2.400",
    provider: "Anthropic",
    keywords: ""
  },
  {
    model: "claude-3-5-sonnet-20240620",
    family: "Claude",
    context: "200K",
    inputCost: "$3.000",
    outputCost: "$15.000",
    provider: "Anthropic",
    keywords: "most intelligent"
  },
  {
    model: "claude-3-5-opus-20240620",
    family: "Claude",
    context: "200K",
    inputCost: "$15.000",
    outputCost: "$75.000",
    provider: "Anthropic",
    keywords: "powerful complex tasks"
  },
  {
    model: "claude-3-haiku-20240307",
    family: "Claude",
    context: "200K",
    inputCost: "$0.250",
    outputCost: "$1.250",
    provider: "Anthropic",
    keywords: "fastest most cost-effective"
  },
  {
    model: "open-mistral-nemo-2407",
    family: "Mistral",
    context: "128K",
    inputCost: "$0.300",
    outputCost: "$0.300",
    provider: "Mistral AI",
    keywords: "state-of-the-art 12B NVIDIA"
  },
  {
    model: "mistral-large-2407",
    family: "Mistral",
    context: "128K",
    inputCost: "$3.000",
    outputCost: "$9.000",
    provider: "Mistral AI",
    keywords: "top-tier reasoning high-complexity sophisticated"
  },
  {
    model: "codestral-2405",
    family: "Mistral",
    context: "32K",
    inputCost: "$1.000",
    outputCost: "$3.000",
    provider: "Mistral AI",
    keywords: "code tasks"
  },
  {
    model: "mistral-embed",
    family: "Mistral",
    context: "8K",
    inputCost: "$0.100",
    outputCost: "N/A",
    provider: "Mistral AI",
    keywords: "semantic text representation"
  },
  {
    model: "open-mistral-7b",
    family: "Mistral",
    context: "32K",
    inputCost: "$0.250",
    outputCost: "$0.250",
    provider: "Mistral AI",
    keywords: "7B transformer fast-deployed customisable"
  },
  {
    model: "open-mixtral-8x7b",
    family: "Mistral",
    context: "32K",
    inputCost: "$0.700",
    outputCost: "$0.700",
    provider: "Mistral AI",
    keywords: "sparse Mixture-of-Experts 45B parameters"
  },
  {
    model: "open-mixtral-8x22b",
    family: "Mistral",
    context: "64K",
    inputCost: "$2.000",
    outputCost: "$6.000",
    provider: "Mistral AI",
    keywords: "most performant open model 141B parameters"
  },
  {
    model: "mistral-small-latest",
    family: "Mistral",
    context: "N/A",
    inputCost: "$1.000",
    outputCost: "$3.000",
    provider: "Mistral AI",
    keywords: "cost-efficient low latency"
  },
  {
    model: "mistral-medium-latest",
    family: "Mistral",
    context: "N/A",
    inputCost: "$2.750",
    outputCost: "$8.100",
    provider: "Mistral AI",
    keywords: "first commercial model"
  },
  {
    model: "gemini-1.5-flash",
    family: "Gemini",
    context: "N/A",
    inputCost: "$0.075",
    outputCost: "$0.300",
    provider: "Google",
    keywords: "flash"
  },
  {
    model: "gemini-1.5-pro",
    family: "Gemini",
    context: "N/A",
    inputCost: "$3.500",
    outputCost: "$10.500",
    provider: "Google",
    keywords: "pro"
  },
  {
    model: "gemini-pro",
    family: "Gemini",
    context: "N/A",
    inputCost: "$0.500",
    outputCost: "$1.500",
    provider: "Google",
    keywords: "pro"
  },
  {
    model: "gemma2-9b-it",
    family: "Gemma",
    context: "8K",
    inputCost: "$0.200",
    outputCost: "$0.200",
    provider: "Groq",
    keywords: "Gemma 2 9B"
  },
  {
    model: "gemma-7b-it",
    family: "Gemma",
    context: "8K",
    inputCost: "$0.070",
    outputCost: "$0.070",
    provider: "Groq",
    keywords: "Gemma 7B"
  },
  {
    model: "llama3-groq-70b-8192-tool-use-preview",
    family: "Llama",
    context: "8K",
    inputCost: "$0.890",
    outputCost: "$0.890",
    provider: "Groq",
    keywords: "Llama 3 70B Tool Use Preview"
  },
  {
    model: "llama3-groq-8b-8192-tool-use-preview",
    family: "Llama",
    context: "8K",
    inputCost: "$0.190",
    outputCost: "$0.190",
    provider: "Groq",
    keywords: "Llama 3 8B Tool Use Preview"
  },
  {
    model: "llama-3.1-70b-versatile",
    family: "Llama",
    context: "128K",
    inputCost: "N/A",
    outputCost: "N/A",
    provider: "Groq",
    keywords: "Llama 3.1 70B Preview"
  },
  {
    model: "llama-3.1-8b-instant",
    family: "Llama",
    context: "128K",
    inputCost: "N/A",
    outputCost: "N/A",
    provider: "Groq",
    keywords: "Llama 3.1 8B Preview"
  },
  {
    model: "llama-guard-3-8b",
    family: "Llama",
    context: "8K",
    inputCost: "N/A",
    outputCost: "N/A",
    provider: "Groq",
    keywords: "Llama Guard 3 8B"
  },
  {
    model: "llama3-70b-8192",
    family: "Llama",
    context: "8K",
    inputCost: "$0.590",
    outputCost: "$0.790",
    provider: "Groq",
    keywords: "Meta Llama 3 70B"
  },
  {
    model: "llama3-8b-8192",
    family: "Llama",
    context: "8K",
    inputCost: "$0.050",
    outputCost: "$0.080",
    provider: "Groq",
    keywords: "Meta Llama 3 8B"
  },
  {
    model: "mixtral-8x7b-32768",
    family: "",
    context: "32K",
    inputCost: "$0.240",
    outputCost: "$0.240",
    provider: "Groq",
    keywords: "Mixtral 8x7B"
  },
  {
    model: "midjourney",
    family: "Image",
    context: "N/A",
    inputCost: "Varies",
    outputCost: "Varies",
    provider: "Midjourney",
    keywords: "image generation variation blend zoom",
    detailedPricing: [
      { type: "imagine", price: "$0.036 / image" },
      { type: "variation", price: "$0.036 / image" },
      { type: "reroll", price: "$0.036 / image" },
      { type: "blend", price: "$0.036 / image" },
      { type: "zoom", price: "$0.018 / image" },
      { type: "pan", price: "$0.036 / image" },
      { type: "describe", price: "$0.018 / image" },
      { type: "upscale", price: "$0.018 / image" }
    ]
  },
  {
    model: "flux",
    family: "Image",
    context: "N/A",
    inputCost: "Varies",
    outputCost: "Varies",
    provider: "Flux",
    keywords: "image generation",
    detailedPricing: [
      { type: "flux-pro", price: "$0.055 / image" },
      { type: "flux-schnell", price: "$0.003 / image" },
      { type: "flux-dev", price: "$0.030 / image" }
    ]
  },
  {
    model: "stable-diffusion",
    family: "Image",
    context: "N/A",
    inputCost: "Varies",
    outputCost: "Varies",
    provider: "Stability AI",
    keywords: "image generation editing upscaling",
    detailedPricing: [
      { type: "generate_core", price: "$0.030 / image" },
      { type: "generate_sd3", price: "$0.065 / image" },
      { type: "generate_ultra", price: "$0.080 / image" },
      { type: "sd3-turbo", price: "$0.040 / image" },
      { type: "upscale_conservative", price: "$0.030 / image" },
      { type: "upscale_creative", price: "$0.250 / image" },
      { type: "edit_erase", price: "$0.030 / image" },
      { type: "edit_inpaint", price: "$0.030 / image" },
      { type: "edit_outpaint", price: "$0.040 / image" },
      { type: "edit_search_replace", price: "$0.040 / image" },
      { type: "edit_remove_background", price: "$0.020 / image" },
      { type: "control_sketch", price: "$0.030 / image" },
      { type: "control_structure", price: "$0.030 / image" }
    ]
  }
];


function MembershipTiers() {
  return (
    <div className="membership-tiers">
      <h3>Membership Tiers and Discounts</h3>
      <div className="tier-cards">
        {[
          { tier: 1, spend: "$0 - $10", discount: "No discount" },
          { tier: 2, spend: "$10.01 - $50", discount: "5% discount" },
          { tier: 3, spend: "$50.01 - $500", discount: "10% discount" },
          { tier: 4, spend: "$500.01 - $5,000", discount: "15% discount" },
          { tier: 5, spend: "$5,000.01+", discount: "20% discount" },
        ].map((tier) => (
          <div key={tier.tier} className="tier-card">
            <div className="tier-header">Tier {tier.tier}</div>
            <div className="tier-spend">{tier.spend}</div>
            <div className="tier-discount">{tier.discount}</div>
          </div>
        ))}
      </div>
      <div className="tier-notes">
        <p>Discounts apply to all OpenAI model series.</p>
        <p className="contact-sales">
          For even higher discounts, <a href="https://discord.gg/3XW9CySszS">contact our sales team</a>.
        </p>
      </div>
      <p className="tier-note">Discounts apply to all OpenAI model series.</p>
    </div>
  );
}



function PriceComparison() {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedModel, setExpandedModel] = useState(null);
  const { isDarkMode } = useTheme();


  const filteredModels = models.filter(model =>
    model.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
    model.provider.toLowerCase().includes(searchTerm.toLowerCase()) ||
    model.keywords.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleExpand = (modelName) => {
    setExpandedModel(expandedModel === modelName ? null : modelName);
  };

  return (
    <div className={`price-comparison ${isDarkMode ? 'dark' : 'light'}`}>
      <div className="header">
        <h1>Models and Price</h1>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search models..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="search-icon">🔍</div>
        </div>
      </div>
      <div className="ezlink-intro">
        <h2>Discover EZLINK AI</h2>
        <div className="ezlink-content">
          <p>
            Welcome to the future of LLM price intelligence. EZLINK AI brings you:
          </p>
          <ul>
            <li>Comprehensive LLM price comparisons</li>
            <li>Real-time, accurate model information</li>
            <li>Intuitive cost calculation tools</li>
          </ul>
          <p className="discount-info">
            <span className="highlight">Exclusive Offer:</span> Get
            <a href="/openai-discount" className="discount-link"> discounted OpenAI API access </a>
            through our platform!
          </p>
        </div>
      </div>

      <MembershipTiers />

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Model</th>
              <th>Provider</th>
              <th>Family</th>
              <th>Context</th>
              <th>Input $/1M tokens</th>
              <th>Output $/1M tokens</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {filteredModels.map((model, index) => (
              <React.Fragment key={index}>
                <tr className={expandedModel === model.model ? 'expanded' : ''}>
                  <td>{model.model}</td>
                  <td>{model.provider}</td>
                  <td>{model.family}</td>
                  <td>{model.context}</td>
                  <td>{model.inputCost}</td>
                  <td>{model.outputCost}</td>
                  <td>
                    {model.detailedPricing && (
                      <button onClick={() => toggleExpand(model.model)} className="details-button">
                        {expandedModel === model.model ? 'Hide' : 'Show'} Details
                      </button>
                    )}
                  </td>
                </tr>
                {expandedModel === model.model && model.detailedPricing && (
                  <tr className="details-row">
                    <td colSpan="6">
                      <table className="details-table">
                        <thead>
                          <tr>
                            {model.detailedPricing[0].type && <th>Type</th>}
                            <th>Resolution</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {model.detailedPricing.map((pricing, idx) => (
                            <tr key={idx}>
                              {pricing.type && <td>{pricing.type}</td>}
                              <td>{pricing.resolution}</td>
                              <td>{pricing.price}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PriceComparison;
