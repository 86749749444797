// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: var(--footer-bg);
  color: var(--footer-text);
  padding: 1rem 0;
  font-family: 'Inter', sans-serif;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
}

.social-links {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.social-links a {
  color: var(--footer-link);
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: var(--footer-link-hover);
}

.copyright {
  font-size: 0.9rem;
  color: var(--footer-text-secondary);
}

/* 确保在你的全局CSS或主题上下文中定义这些变量 */
:root {
  --footer-bg: #f5f5f5;
  --footer-text: #333;
  --footer-text-secondary: #666;
  --footer-link: #3498db;
  --footer-link-hover: #2980b9;
}

[data-theme='dark'] {
  --footer-bg: #222;
  --footer-text: #f5f5f5;
  --footer-text-secondary: #b0b0b0;
  --footer-link: #3498db;
  --footer-link-hover: #5dade2;
}

@media (max-width: 768px) {
  .footer-container {
    padding: 0 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,yBAAyB;EACzB,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,iBAAiB;EACjB,mCAAmC;AACrC;;AAEA,4BAA4B;AAC5B;EACE,oBAAoB;EACpB,mBAAmB;EACnB,6BAA6B;EAC7B,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,gCAAgC;EAChC,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".footer {\r\n  background-color: var(--footer-bg);\r\n  color: var(--footer-text);\r\n  padding: 1rem 0;\r\n  font-family: 'Inter', sans-serif;\r\n}\r\n\r\n.footer-container {\r\n  max-width: 1200px;\r\n  margin: 0 auto;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  padding: 0 2rem;\r\n}\r\n\r\n.social-links {\r\n  display: flex;\r\n  gap: 1rem;\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n.social-links a {\r\n  color: var(--footer-link);\r\n  text-decoration: none;\r\n  transition: color 0.3s ease;\r\n}\r\n\r\n.social-links a:hover {\r\n  color: var(--footer-link-hover);\r\n}\r\n\r\n.copyright {\r\n  font-size: 0.9rem;\r\n  color: var(--footer-text-secondary);\r\n}\r\n\r\n/* 确保在你的全局CSS或主题上下文中定义这些变量 */\r\n:root {\r\n  --footer-bg: #f5f5f5;\r\n  --footer-text: #333;\r\n  --footer-text-secondary: #666;\r\n  --footer-link: #3498db;\r\n  --footer-link-hover: #2980b9;\r\n}\r\n\r\n[data-theme='dark'] {\r\n  --footer-bg: #222;\r\n  --footer-text: #f5f5f5;\r\n  --footer-text-secondary: #b0b0b0;\r\n  --footer-link: #3498db;\r\n  --footer-link-hover: #5dade2;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .footer-container {\r\n    padding: 0 1rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
