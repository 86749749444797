import React from 'react';
import { useTheme } from './ThemeContext';
import { FaSun, FaMoon } from 'react-icons/fa';
import './Navbar.css';

function Navbar() {
  const { isDarkMode, toggleTheme } = useTheme();

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-brand">
          <span>LLMs</span>Price
        </div>
        <ul className="navbar-links">
          <li><a href="https://www.ezlinkai.com/">Home</a></li>
          <li><a href="https://docs.ezlinkai.com/">Docs</a></li>
          <li><a href="https://platform.ezlinkai.com/#/">Sign In</a></li>
        </ul>
        <div className="navbar-right">
          <span className="made-by">EZLINK AI</span>
          <button onClick={toggleTheme} className="theme-toggle" aria-label="Toggle theme">
            {isDarkMode ? <FaSun /> : <FaMoon />}
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
